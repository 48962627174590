import { createGlobalStyle } from 'styled-components';
import { brand, text } from './colors';

const Typography = createGlobalStyle`
  html {
    font-size: 62.5%; /* Allows for easy rem conversion i.e 16px = 1.6rem */
  }

  body {
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.6;
    color: ${text};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media screen and (min-width: 768px) {
      font-size: 1.8rem;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    color: ${brand};
    margin: 0;
  }

  h1 {
    font-size: 3.4rem;
    margin-bottom: 1.5rem;
  }

  h2 {
    font-size: 2.4rem;
    margin-bottom: 1rem;
  }
  
  h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  p {
    margin: 0 0 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: ${text};
  }
`;

export default Typography;

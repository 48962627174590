import React from 'react';
import styled from 'styled-components';
import instagramIcon from '../assets/images/instagram.svg';
import twitterIcon from '../assets/images/twitter.svg';
import facebookIcon from '../assets/images/facebook.svg';

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 0 -1.5rem;
  display: flex;
`;

const Item = styled.li`
  margin: 0 1.5rem;
`;

const Link = styled.a`
  display: flex;
`;

export default function SocialLinks({ className }) {
  return (
    <List className={className}>
      <Item>
        <Link
          href="https://www.instagram.com/cafunecafeuk"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagramIcon} alt="Instagram" />
        </Link>
      </Item>
      <Item>
        <Link
          href="https://twitter.com/cafunecafeuk"
          target="_blank"
          rel="noreferrer"
        >
          <img src={twitterIcon} alt="Twitter" />
        </Link>
      </Item>
      <Item>
        <Link
          href="https://www.facebook.com/cafunecafeuk"
          target="_blank"
          rel="noreferrer"
        >
          <img src={facebookIcon} alt="Facebook" />
        </Link>
      </Item>
    </List>
  );
}

import 'main.css/dist/_base.css';
import 'hamburgers/dist/hamburgers.css';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  /*
   * apply a natural box layout model to all elements,
   * but allowing components to change
   */
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  /* responsive images */
  img {
    max-width: 100%;
  }

  p + a[class^='Button'] {
    margin-top: 1rem;
  }
`;

export default GlobalStyles;

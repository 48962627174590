import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Nav from './Nav';
import logo from '../assets/images/logo.svg';
import SocialLinks from './SocialLinks';
import Hamburger from './Hamburger';

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  transition: background-color 0.3s;
  background-color: white;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.15);
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  max-width: 114rem;
`;

const Logo = styled.img`
  height: 3rem;

  @media screen and (min-width: 768px) {
    height: 4.5rem;
  }
`;

const StyledHamburger = styled(Hamburger)`
  padding: 0;
  display: inline-flex;

  @media screen and (min-width: 768px) {
    display: none;
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after,
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #8f6627 !important;
  }
`;

const StyledSocialLinks = styled(SocialLinks)`
  display: none;

  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

export default function Header() {
  const [menuOpen, updateMenuOpen] = useState(false);

  const toggleMenuOpen = () => {
    updateMenuOpen(prevMenuOpen => !prevMenuOpen);
  };

  return (
    <StyledHeader>
      <Wrapper>
        <Link to="/">
          <Logo src={logo} alt="Cafuné logo" />
        </Link>
        <Nav isOpen={menuOpen} onClick={toggleMenuOpen} />
        <StyledSocialLinks />
        <StyledHamburger onClick={toggleMenuOpen} isActive={menuOpen} />
      </Wrapper>
    </StyledHeader>
  );
}

import React from 'react';
import styled from 'styled-components';
import useContactQuery from '../hooks/useContactQuery';

const StyledAddress = styled.address`
  font-style: normal;
  margin-bottom: 3rem;
  white-space: pre-line;
`;

const Directions = styled.a`
  transition: color ease 0.3s;

  &:hover {
    color: black;
  }
`;

export default function Address() {
  const data = useContactQuery();

  return (
    <>
      <StyledAddress>{data.address}</StyledAddress>
      <Directions href={data.directions} target="_blank" rel="noreferrer">
        Directions
      </Directions>
    </>
  );
}

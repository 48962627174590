import React from 'react';
import styled from 'styled-components';
import useContactQuery from '../hooks/useContactQuery';

const StyledHours = styled.div`
  white-space: pre-line;
  line-height: 1.8;
`;

export default function Hours() {
  const data = useContactQuery();

  return <StyledHours>{data.hours}</StyledHours>;
}

import React from 'react';
import styled from 'styled-components';
import useContactQuery from '../hooks/useContactQuery';

const StyledEmailAddressLink = styled.a`
  display: block;
  text-decoration: none;
  transition: color ease 0.3s;
  margin-bottom: 2.5rem;
  text-decoration: underline;

  &:hover {
    color: black;
  }
`;

export default function EmailAddressLink() {
  const data = useContactQuery();

  return (
    <StyledEmailAddressLink
      href={`mailto:${data.email}`}
      target="_blank"
      rel="noreferrer"
    >
      {data.email}
    </StyledEmailAddressLink>
  );
}

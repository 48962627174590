import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { brand } from '../styles/colors';

const StyledNav = styled.nav`
  transition: opacity 0.3s;

  @media screen and (max-width: 768px) {
    visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
    opacity: ${props => (props.isOpen ? '1' : '0')};
    background: white;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.6rem;
  }
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const ListItem = styled.li`
  margin: 0 1.5rem;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 768px) {
    margin: 0 0 1.5rem;
    text-align: center;
  }
`;

const StyledLink = styled(Link)`
  margin: 0;
  text-decoration: none;
  font-weight: 700;
  color: ${brand};
  position: relative;

  &.active {
    &:after {
      content: '';
      height: 0.5rem;
      position: absolute;
      top: 100%;
      margin-top: 2.5rem;
      left: 0;
      width: 100%;
      background-color: ${brand};

      @media screen and (max-width: 768px) {
        margin-top: 0;
        height: 0.3rem;
      }
    }
  }
`;

const MenuLink = styled.a`
  margin: 0;
  text-decoration: none;
  font-weight: 700;
  color: ${brand};
  position: relative;

  &.active {
    &:after {
      content: '';
      height: 0.5rem;
      position: absolute;
      top: 100%;
      margin-top: 2.5rem;
      left: 0;
      width: 100%;
      background-color: ${brand};

      @media screen and (max-width: 768px) {
        margin-top: 0;
        height: 0.3rem;
      }
    }
  }
`;

export default function Nav({ isOpen, onClick }) {
  return (
    <StyledNav isOpen={isOpen}>
      <List isOpen={isOpen}>
        <ListItem>
          <StyledLink to="/about/" activeClassName="active" onClick={onClick}>
            About
          </StyledLink>
        </ListItem>
        <ListItem>
          <MenuLink
            href="/cafuné-menu.pdf"
            target="_blank"
            rel="noreferrer"
            onClick={onClick}
          >
            Menu
          </MenuLink>
        </ListItem>
        <ListItem>
          <StyledLink to="/gallery/" activeClassName="active" onClick={onClick}>
            Gallery
          </StyledLink>
        </ListItem>
        <ListItem>
          <StyledLink to="/find-us/" activeClassName="active" onClick={onClick}>
            Find Us
          </StyledLink>
        </ListItem>
      </List>
    </StyledNav>
  );
}

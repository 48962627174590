import { useStaticQuery, graphql } from 'gatsby';

export default function useContactQuery() {
  const data = useStaticQuery(graphql`
    {
      allSanityContact {
        edges {
          node {
            address
            directions
            hours
            email
          }
        }
      }
    }
  `);

  return data.allSanityContact.edges[0].node;
}

import React from 'react';
import 'normalize.css';
import styled from 'styled-components';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import Header from './Header';
import Footer from './Footer';

const Container = styled.div``;

export default function Layout({ children }) {
  return (
    <Container>
      <GlobalStyles />
      <Typography />
      <Header />
      {children}
      <Footer />
    </Container>
  );
}

export const brand = '#906728';
export const orange = '#e88512';
export const yellow = '#efd750';
export const red = '#c71437';
export const redLight = '#e15456';
export const blue = '#58bace';
export const green = '#2ba15f';
export const grey = '#f5f5f5';
export const sand = 'rgb(242 219 187 / 25%)';
export const text = 'rgba(0, 0, 0, 0.6)';

import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Hours from './Hours';
import Address from './Address';
import EmailAddressLink from './EmailAddressLink';
import SocialLinks from './SocialLinks';
import line from '../assets/images/line.svg';

const StyledFooter = styled.footer`
  position: relative;
  display: flex;
  justify-content: center;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 1.6rem;
  padding: 4rem 2rem;
  background-color: white;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1rem;
    background: url(${line});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

const Container = styled.div`
  text-align: center;

  @media screen and (min-width: 1200px) {
    text-align: left;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
  }
`;

const Col = styled.div`
  margin-bottom: 4rem;

  @media screen and (min-width: 1200px) {
    margin-right: 17rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const NavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NavListItem = styled.li`
  margin-bottom: 1rem;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  transition: color ease 0.3s;

  &:hover {
    color: black;
  }
`;

const Meta = styled.div`
  display: block;
  margin-top: 3rem;
  font-size: 1.4rem;

  @media screen and (min-width: 1200px) {
    display: flex;
    justify-content: space-between;
  }
`;

const SocialLinksWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (min-width: 1200px) {
    display: block;
  }
`;

export default function Footer() {
  return (
    <StyledFooter>
      <Container>
        <Row>
          <Col>
            <nav>
              <NavList>
                <NavListItem>
                  <NavLink to="/">Home</NavLink>
                </NavListItem>
                <NavListItem>
                  <NavLink to="/about/">About</NavLink>
                </NavListItem>
                <NavListItem>
                  <NavLink
                    as="a"
                    href="/cafuné-menu.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Menu
                  </NavLink>
                </NavListItem>
                <NavListItem>
                  <NavLink to="/gallery/">Gallery</NavLink>
                </NavListItem>
                <NavListItem>
                  <NavLink to="/find-us/">Find Us</NavLink>
                </NavListItem>
              </NavList>
            </nav>
          </Col>
          <Col>
            <Address />
          </Col>
          <Col>
            <Hours />
          </Col>
          <Col>
            <EmailAddressLink />
            <SocialLinksWrapper>
              <SocialLinks />
            </SocialLinksWrapper>
          </Col>
        </Row>
        <Meta>
          <div>&copy; {new Date().getFullYear()} Cafuné</div>
          <div>
            Website by{' '}
            <a
              href="https://www.apamphilon.com"
              target="_blank"
              rel="noreferrer"
            >
              apamphilon
            </a>
          </div>
        </Meta>
      </Container>
    </StyledFooter>
  );
}
